import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';

import { queryClient } from 'lib/core/reactQueryClient';

import { AbacProvider } from 'contexts/AbacContext';
import { AlertProvider } from 'contexts/AlertContext';
import { AuthProvider } from 'contexts/AuthContext';
import { CartProvider } from 'contexts/CartContext';
import { IntlProvider } from 'contexts/IntlContext';

import AppRoutes from './pages/AppRoutes';
import { CssReset } from './styles/global';
import { theme } from './styles/themes/main';

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <IntlProvider>
        <AuthProvider>
          <AbacProvider>
            <AlertProvider>
              <CartProvider>
                <AppRoutes />
                <ReactQueryDevtools initialIsOpen={false} />
              </CartProvider>
            </AlertProvider>
          </AbacProvider>
        </AuthProvider>
      </IntlProvider>
      <CssReset />
    </ThemeProvider>
  </QueryClientProvider>
);

export default App;
