import styled, { css, keyframes } from 'styled-components';

import DefaultDivider from 'components/shared/core/divider';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.span`
  ${({ theme }) => css`
    width: 25px;
    height: 25px;
    border-radius: ${theme.new.shapes.borderRadius.full};
    border: ${theme.new.shapes.borderWidth.xl} solid
      ${theme.new.colors.secondary.dark};
    border-bottom: ${theme.new.shapes.borderWidth.xl} solid transparent;
    -webkit-animation: ${spin} 1s linear infinite;
    animation: ${spin} 1s linear infinite;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.surface.accordion};
    padding: ${theme.spacing.sm} ${theme.spacing.md};
    border-radius: ${theme.borderRadius.md};
  `}
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;
export const Title = styled.h3`
  color: ${({ theme }) => theme.texts.lightSecondary};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;

  svg {
    width: 23.95px;
    height: 28px;
  }
`;

export const Divider = styled(DefaultDivider)`
  margin-top: ${({ theme }) => theme.spacing.md};
`;

export const Content = styled.main``;
