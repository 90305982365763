import { useAlert } from 'contexts';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { ReportInformation } from 'types/BillingScheduling';
import { downloadArchive } from 'utils-general/downloadArchive';

import { getComissionReport, postComissionReport } from 'services/billings';

export const useReportComission = () => {
  const intl = useIntl();
  const { showAlert, setClear } = useAlert();
  const [fileName, setFileName] = useState('');
  const { mutateAsync } = useMutation(
    (body: ReportInformation) => postComissionReport(body),
    {
      onSuccess: (values) => {
        setFileName(values?.fileName);
      },
      onError: () => {
        setClear();
        process.nextTick(() => {
          showAlert({
            title: intl.formatMessage({ id: 'report' }),
            type: 'error',
          });
        });
      },
    }
  );

  const { refetch: catchReport } = useQuery(
    ['comissionReport'],
    () => getComissionReport(fileName),
    {
      enabled: !!fileName,
      onSuccess: (values) => {
        if (values.processingStatus === 'processing') {
          setTimeout(() => {
            catchReport();
          }, 60000);
          return;
        }
        if (values.processingStatus === 'error') {
          setClear();
          process.nextTick(() => {
            showAlert({
              title: intl.formatMessage({ id: 'report' }),
              type: 'error',
            });
          });
          setFileName('');
          return;
        }
        setClear();
        process.nextTick(() => {
          showAlert({
            title: intl.formatMessage({
              id: 'report.generated',
            }),
            type: 'success',
            message: intl.formatMessage({
              id: 'electronicBillingReport.timeToGenerateReport',
            }),
          });
        });
        setFileName('');
        downloadArchive(values.url);
      },
      onError: () => {
        setClear();
        process.nextTick(() => {
          showAlert({
            title: intl.formatMessage({ id: 'report' }),
            type: 'error',
          });
        });
      },
    }
  );

  const postReport = (reportInformation: ReportInformation) => {
    mutateAsync(reportInformation);
    process.nextTick(() => {
      showAlert({
        title: intl.formatMessage({
          id: 'electronicBillingReport.generateReport',
        }),
        type: 'loading',
        message: intl.formatMessage({
          id: 'electronicBillingReport.timeToGenerateReport',
        }),
      });
    });
  };

  return {
    postReport,
  };
};
