export const colors = {
  blues: {
    500: 'hsla(216, 100%, 53%, 1)',
    550: 'hsla(230, 80%, 34%, 1)',
    560: 'hsla(230, 80%, 26%, 1)',
    600: 'hsla(235, 76%, 27%, 1)',
    700: 'rgba(17, 26, 122, 0.3)',
  },
  beges: {
    500: 'hsla(34, 83%, 69%, 1)',
    600: 'hsla(34, 64%, 58%, 1)',
    700: 'rgba(209, 129, 21, 0.33)',
  },
  grays: {
    350: 'hsla(240, 28%, 95%, 1)',
    400: 'hsla(235, 32%, 93%, 1)',
    500: 'hsla(235, 31%, 93%, 1)',
    550: 'hsla(236, 38%, 92%, 1)',
    600: 'hsla(235, 52%, 86%, 1)',
    640: 'hsla(240, 26%, 73%, 1)',
    641: 'hsla(240, 21%, 64%, 1)',
    643: 'hsla(240, 19%, 41%, 1)',
    647: 'hsl(240, 19%, 27%, 1)',
    650: 'hsla(240, 26%, 82%, 1)',
    700: 'hsla(234, 17%, 67%, 1)',
    710: 'hsla(235, 17%, 67%, 1)',
    719: 'hsl(235, 52%, 86%, 1)',
    720: 'hsla(235, 52%, 86%, 0.3)',
    800: 'rgba(201, 204, 238, 0.3)',
  },
  reds: {
    500: 'hsla(342, 80%, 52%, 1)',
  },
  whites: {
    500: 'hsla(0, 0%, 100%, 1)',
  },
  greens: {
    400: 'hsla(133, 57%, 53%, 1)',
    500: 'hsla(133, 58%, 39%, 1)',
  },
  orange: {
    500: 'hsla(18, 81%, 57%, 1)',
  },
  transparent: 'transparent',
} as const;
