import axios, { AxiosRequestConfig } from 'axios';

import refreshUserAuthToken from 'lib/contextual/refreshToken';

import { GenericApiClient } from './genericApiCLient';

const apiInstance = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

refreshUserAuthToken(apiInstance);
type Nullable<T> = T | null | undefined;
const noSensediaInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    transactionid: 'CONTENT_HOST_HEADER',
    'Content-Host': 'trf-installment-ms-v2',
  },
});

const sellerApi = new GenericApiClient({
  headers: {
    'x-auth-token': process.env.REACT_APP_SELLER_AUTH,
    'Content-Type': 'application/json',
  },
});

function getContentHostByURL(url: string): Nullable<string> {
  const map: Record<string, string> = {
    '/adyen-adapter': 'trf-adyen-adp-ms-v2',
    '/affiliate-manager': 'trf-affiliate-ms-v1',
    '/associate-manager': 'trf-associate-manager-ms-v2',
    '/bank-slip': 'boletos-ms-v1',
    '/batches': 'boletos-ms-v1',
    '/cart': 'trf-cart-ms-v1',
    '/contract-manager': 'trf-contract-manager-ms-v2',
    '/document-manager': 'trf-document-ms-v1',
    '/employer-manager': 'trf-employers-ms-v1',
    '/installment-manager': 'trf-installment-ms-v2',
    '/locations': 'locations',
    '/payment-method': 'trf-payment-method-ms-v2',
    '/pdfs': 'trf-pdfs-ms-v1',
    '/service-after-sales': 'service-after-sales',
    '/service-collector': 'service-collector',
    '/user-manager': 'trf-user-manager-ms-v1',
    '/zelo-delivery': 'delivery-manager-ms-v1',
    '/zelo-touchpoint': 'zelo-touchpoint',
    '/service-billing': 'service-billing',
    '/finance-department': 'finance-department',
    '/columbarium': 'service-columbarium',
    '/customer-attendance-reports': 'customer-attendance-reports',
    '/orthopedic-support': 'service-orthopedic-support',
    '/bank-shield': 'bank-shield-ms-v1',
    '/service-deposito-boletos': 'service-deposito-boletos',
    '/service-documents': 'service-documents',
    '/service-fee': 'service-fee',
    '/service-bank-slips': 'service-bank-slips',
  };

  const parsedUrl = new URL(url);

  if (parsedUrl.pathname.startsWith('/dev')) {
    return map[
      parsedUrl.pathname.replace('/dev', '').split('/').slice(0, 2).join('/')
    ];
  } else if (parsedUrl.pathname.startsWith('/qa')) {
    return map[
      parsedUrl.pathname.replace('/qa', '').split('/').slice(0, 2).join('/')
    ];
  } else if (parsedUrl.pathname.startsWith('/prod')) {
    return map[
      parsedUrl.pathname.replace('/prod', '').split('/').slice(0, 2).join('/')
    ];
  }

  return map[parsedUrl.pathname.split('/').slice(0, 2).join('/')];
}

const getRequest = <T = any>(
  path: string,
  requestOptions?: AxiosRequestConfig
) =>
  apiInstance
    .get<T>(path, {
      ...requestOptions,
      headers: {
        ...requestOptions?.headers,
        'Content-Host': getContentHostByURL(path),
      },
    })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

const getRequestNoSensedia = (
  path: string,
  requestOptions?: AxiosRequestConfig
) =>
  noSensediaInstance
    .get(path)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

const postRequestNoSensedia = (path: string, data: any) =>
  noSensediaInstance
    .post(path, data)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

const postRequest = <T = any>(
  path: string,
  payload: {},
  requestOptions?: AxiosRequestConfig
) =>
  apiInstance.post<T>(path, payload, {
    ...requestOptions,
    headers: {
      ...requestOptions?.headers,
      'Content-Host': getContentHostByURL(path),
    },
  });

const putRequest = (
  path: string,
  payload = {},
  requestOptions?: AxiosRequestConfig
) =>
  apiInstance
    .put(path, payload, {
      ...requestOptions,
      headers: {
        ...requestOptions?.headers,
        'Content-Host': getContentHostByURL(path),
      },
    })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

const deleteRequest = <T = any>(
  path: string,
  payload = {},
  requestOptions?: AxiosRequestConfig
) =>
  apiInstance
    .delete<T>(path, {
      ...requestOptions,
      headers: {
        ...requestOptions?.headers,
        'Content-Host': getContentHostByURL(path),
      },
    })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

const patchRequest = <T = any>(
  path: string,
  payload = {},
  requestOptions?: AxiosRequestConfig
) =>
  apiInstance
    .patch<T>(path, payload, {
      ...requestOptions,
      headers: {
        ...requestOptions?.headers,
        'Content-Host': getContentHostByURL(path),
      },
    })
    .then((response) => response);

const api = {
  getRequest,
  getRequestNoSensedia,
  postRequest,
  postRequestNoSensedia,
  putRequest,
  deleteRequest,
  patchRequest,
  sellerApi,
};

export default api;
