import styled from 'styled-components';

const HomeContent = styled.div`
  padding: 6vh 8vw;
  min-height: 100%;

  @media screen and (max-width: 703px) {
    padding: 7vw;
  }
`;

export default HomeContent;
