import styled, { css } from 'styled-components';

import Skeleton from 'components/shared/newCore/Skeleton';

interface ContainerProps {
  disabled?: boolean;
  isAccordionRegistrationDataForm?: boolean;
}

interface ContentProps {
  error?: boolean;
  border?: boolean;
  isAccordionRegistrationDataForm?: boolean;
}

export const Container = styled.main<ContainerProps>`
  cursor: pointer;
  opacity: ${(props) => props.disabled && 0.3};
  ${(props) =>
    props.isAccordionRegistrationDataForm &&
    css`
      width: 100%;
    `}
`;

export const Content = styled.div<ContentProps>`
  width: 100%;
  position: relative;
  background-color: #fff;
  border-radius: ${(props) => props.theme.borderRadius.md};
  border: ${({ error, theme }) =>
    error ? `1px solid ${theme.contextual.error}` : 'none'};

  ${({ border }) =>
    border &&
    css`
      border: 1px solid #c9ccee;
    `}

  &:focus-within,
  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.input.primary.focusColor};
  }
  margin-top: ${({ theme }) => theme.spacing.xs};
`;

export const SkeletonWrapper = styled(Skeleton)`
  margin-top: ${({ theme }) => theme.spacing.xs};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  height: 48px;
`;

export const Icon = styled.span`
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  font-size: 24px;
  right: 10px;
  color: ${(props) => props.theme.primary.main};
`;

export const Label = styled.label`
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-weight: 600;
  display: block;
  margin-top: 4px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.secondary.dark};
  text-transform: uppercase;

  span {
    color: ${({ theme }) => theme.contextual.error};
  }
`;

export const Input = styled.select`
  width: 100%;
  padding: ${({ theme }) => `14px ${theme.spacing.sm}`};
  background-color: transparent;
  border: none;
  appearance: none;
  outline: none;
  height: 48px;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: ${({ theme }) => theme.input.primary.textColor};
  font-size: ${(props) => props.theme.fontSizes.small};
  cursor: pointer;
`;

export const Option = styled.option`
  color: ${({ theme }) => theme.texts.options};
`;
export const DefaultOption = styled.option`
  color: ${({ theme }) => theme.texts.muted};
`;

export const ErrorLabel = styled.small`
  color: ${(props) => props.theme.contextual.error};
  font-size: ${(props) => props.theme.fontSizes.small};
`;
