export const LocalStorageKeys = {
  avatar: 'user.avatar',
  name: 'user.name',
  token: 'user.token',
  cognitoId: 'user.cognitoId',
  roles: 'user.roles',
  cashRegisterStatus: 'cashRegister',
  branches: 'branches',
  associatesUpdateResolveds: 'associatesUpdateResolveds',
  schedulePaymentsAssociates: 'schedulePaymentsAssociates',
};

export const USER_ID = 'id';
export const USER_EMAIL = 'email';
export const USER_NAME = 'name';
export const USER_AVATAR = 'avatar';
export const USER_ROLES = 'roles';
export const USER_BRANCH = 'branches';
export const ASSOCIATES_UPDATE_RESOLVEDS = 'associatesUpdateResolveds';
export const SCHEDULE_PAYMENTS_ASSOCIATES = 'schedulePaymentsAssociates';
