import ConfirmDialog, { AlertType } from 'components/shared/core/confirmDialog';
import RoundedIcon from 'components/shared/core/roundedIcon';

import { ReactComponent as ArrowRight } from 'assets/images/ArrowRight.svg';

interface DefaultConfirmModalProps {
  iconType: AlertType;
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
  confirmLabel: string;
  cancelLabel: string;
  title: string;
  onOpenChange: (open: boolean) => void;
}

const DefaultConfirmModal = ({
  open,
  onConfirm,
  onOpenChange,
  onCancel,
  title,
  cancelLabel,
  confirmLabel,
}: DefaultConfirmModalProps) => (
  <ConfirmDialog
    iconType="warn"
    open={open}
    onConfirm={onConfirm}
    onOpenChange={onOpenChange}
    onCancel={onCancel}
    title={title}
    cancelLabel={cancelLabel}
    confirmLabel={confirmLabel}
    confirmIcon={
      <RoundedIcon>
        <ArrowRight />
      </RoundedIcon>
    }
  />
);

export default DefaultConfirmModal;
