import styled, { css } from 'styled-components';

interface GridProps {
  gap?: string;
}

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  grid-gap: ${({ gap, theme }) => gap ?? theme.spacing.md};

  @media screen and (max-width: 820px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

type RowProps = {
  span?:
    | '2'
    | '2.5'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12';
  flex?: boolean;
};

export const Row = styled.div<RowProps>`
  ${(props) =>
    props.span &&
    css`
      grid-column: span ${props.span};
    `}
`;
