export const Urls = {
  // LOGIN
  Login: '/login',
  RecoverPassword: '/esqueci-senha/*',

  // HOME
  HomePage: '/',

  // CASH
  CashFlows: '/caixa',
  OpenBox: '/caixa/abertura',
  CloseBox: '/caixa/fechamento',
  CashOutWithdrawals: '/caixa/saidas-e-retiradas',
  reversal: '/caixa/estornos',
  CashReport: '/caixa/relatorio-caixa',
  subMenuMotoboy: '/caixa/menu-cobrancas',
  schedulesAndDelays: '/caixa/menu-cobrancas/agendamentos-e-atrasos',
  schedulesLookUpByCollector: '/caixa/menu-cobrancas/consultar-agendas',
  reportsOfBillings: '/caixa/menu-cobrancas/relatorio-de-cobranca',
  syncCalendar: '/caixa/menu-cobrancas/sincronizar-agenda',

  // ASSOCIATES
  Attendance: '/atendimentos',
  partnersDetails: '/associados/:id',
  InstallmentsDebts: '/associados/:id/parcelas-e-debitos',
  DependentsOverview: '/associados/:id/dependentes',
  NewDependents: '/associados/:id/dependentes/novo',
  EditDependent: '/associados/:id/dependentes/:dependent/editar',
  PlanCard: '/associados/:id/carteirinhas',
  ChangeOwnership: '/associados/:id/titulares',
  NewOwnership: '/associados/:id/titulares/novo',
  NewOwnershipFromDependant: '/associados/:id/titulares/:dependent/novo',
  CancelPlan: '/associados/:id/plano/cancelar',
  PaymentMethod: '/associados/:id/pagamentos',
  DeathHistory: '/associados/:id/historico-de-obitos',
  CremationAddition: '/associados/:id/cremacoes',
  Observation: '/associados/:id/observacoes',
  NegociateGracePeriod: '/associados/:id/carencia',
  editPartners: '/associados/:id/editar',
  ShoppingCart: '/associados/:id/carrinho',
  Delivery: '/associados/:id/delivery',
  MembershipFee: '/associados/:id/taxas',
  MembershipNewFee: '/associados/:id/taxas/new',
  MembershipFeeEdit: '/associados/:id/taxas/edit',
  Vaults: '/associados/:id/jazigos',
  LoanInfo: '/associados/:id/emprestimos',

  // MANAGER
  Management: '/gerenciamento',

  // USER MANAGER
  UserList: '/gerenciamento/usuarios',
  UserForm: '/gerenciamento/usuarios/:id',

  // BRANCH MANAGER
  BranchList: '/gerenciamento/filiais',
  BranchForm: '/gerenciamento/filiais/:id',

  // Relatório de atualização cadastral
  AssociateUpdateReport: '/gerenciamento/relatorio-atualizacao-cadastral',

  //obituary
  Obituary: '/obituario',
  DeathForm: '/obituario/dados-do-obito/:id',
  DeclarantForm: '/obituario/declarante/:id',
  ServiceOrder: '/obituario/ordem-de-servico/:id',
  NewServiceOrder: '/obituario/ordem-de-servico/:id/nova-ordem-de-servico',
  RequiredDocuments: '/obituario/documentos-exigidos/:id',

  //paymentSlip
  paymentSlip: '/boletos',
  menuPaymentSlip: '/boletos/menu',
  createPaymentSlip: '/boletos/criar',
  printPaymentSlip: '/boletos/impressao',

  //onlinePaymentSlip
  onlinePaymentSlip: '/boletos/online',
  createOnlinePaymentSlip: '/boletos/online/criar',

  // Delivery Management
  deliveryManagement: '/controle-de-entregas',
  newDelivery: '/controle-de-entregas/nova-entrega',
  searchDeliveries: '/controle-de-entregas/buscar-entrega',
  manageDelivery: '/controle-de-entregas/buscar-entrega/:id',
  manageDeliveryBatches: '/controle-de-entregas/buscar-entrega/:id/lote',
  deliveriesReport: '/controle-de-entregas/relatorio-entrega',

  //Add new Client
  newClientMenu: '/new-client-menu',
  printReport: '/new-client-menu/print-report',
  newClientForm: '/new-client-form',
  printReportUpsell: '/new-client-menu/reportUpsell',

  // Tuition Readjustment
  tuitionReadjustment: '/reajuste-de-mensalidades',
  tuitionLimitsParameterization:
    '/reajuste-de-mensalidades/parametrizacao-de-limites',
  tuitionFitApprover: '/reajuste-de-mensalidades/aprovador-de-reajuste',
  monthlyApprovalHistory: '/reajuste-de-mensalidades/historico-de-aprovacoes',
  monthlyApprovalHistoryRemoteRequirementAdjusment:
    '/reajuste-de-mensalidades/historico-de-aprovacoes/:id',

  // Cobrança ao associado
  associateChargeMenu: '/menu-cobranca-associado',
  schedulePayment: '/menu-cobranca-associado/agendar-pagamento',
  reportAndResearchMenu: '/menu-relatorio-consulta',
  findMySchedules: '/consulta-meus-agendamentos',
  currentCampaign: '/consulta-campanha-vigente',
  collectionCommissionReport: '/relatorio-de-comissoes',
  electronicBillingReport: '/relatorio-de-cobranca-eletronica',

  // Pós venda
  postSellingReport: '/menu-pos-venda',
  UpSellAfterSales: '/UpSell',

  // Financial
  financial: '/financeiro',
  installmentTransactions: '/financeiro/movimentacoes-parcelas',
  installmentTransactionsList: '/financeiro/movimentacoes-parcelas/:id',
  cashReport: '/financeiro/relatorio-caixa',
  depositOfReceipts: '/financeiro/deposito-de-recebimentos',

  // Graves
  graves: '/jazigos',
  newGrave: '/jazigos/novo',
  editGrave: '/jazigos/:id/editar',

  // Management Reports
  managementReports: '/relatorios-gerenciais',
  salesReport: '/relatorios-gerenciais/vendas',
  associatesByStatusReport: '/relatorios-gerenciais/associados-por-status',
  companiesReport: '/relatorios-gerenciais/plano-empresa',
  canceledSlipsReport: '/relatorios-gerenciais/boletos-cancelados',

  // Apoio Ortopédico
  orthopedicSupport: '/apoio-ortopedico',
  orthopedicGroups: '/apoio-ortopedico/grupos',
  orthopedicEquipments: '/apoio-ortopedico/equipamentos',

  // Apoio Ortopédico - Movimentos
  orthopedicMovements: '/apoio-ortopedico/emprestimo-devolucao',
  movementsLoans: '/apoio-ortopedico/emprestimo-devolucao/emprestimo',
  movementsDevolution: '/apoio-ortopedico/emprestimo-devolucao/devolucao',
  movementsDevolutionId: '/apoio-ortopedico/emprestimo-devolucao/devolucao/:id',
  movementsConsult: '/apoio-ortopedico/emprestimo-devolucao/consulta',

  // Apoio Ortopédico - Manutenção
  orthopedicMaintenance: '/apoio-ortopedico/manutencao',
  orthopedicMaintenanceList: '/apoio-ortopedico/manutencao/lista',
  orthopedicMaintenanceUpdate: '/apoio-ortopedico/manutencao/atualizacao',
};
