export const spacing = {
  xs: '8px',
  xs1: '12px',
  xs2: '14px',
  sm: '16px',
  'sm0.5': '18px',
  sm1: '24px',
  sm2: '28px',
  md: '32px',
  lg: '40px',
  lg1: '44px',
  lg2: '46px',
  'lg2.1': '48px',
  'lg2.5': '56px',
  lg3: '60px',
} as const;
