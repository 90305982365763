import {
  CityGroup,
  CityGroupCreation,
  CityGroupResponse,
  CityGroupsResponse,
} from 'types/CityGroup';

import api from 'services/api';

import { getURLWithParams } from 'lib/contextual/Url';

import { CITY_GROUP } from 'constants/endpoints';

export const cityGroup = () => {
  const getAllCityGroups = async ({
    pageParam = 1,
  }): Promise<{
    items: CityGroupsResponse[];
    meta: { itemCount: number; itemsPerPage: number; currentPage: number };
  }> => {
    const params = { page: pageParam, limit: 30 };

    const url = getURLWithParams(CITY_GROUP.GET_ALL, params);

    const allGroups = await api.getRequest(url, {
      headers: { 'Content-Host': 'boletos-ms-v1' },
    });

    return allGroups.data;
  };

  const getCityGroupById = async (id: string): Promise<CityGroupResponse> => {
    const url = CITY_GROUP.GET_BY_ID(id);

    const group = await api.getRequest(url, {
      headers: { 'Content-Host': 'boletos-ms-v1' },
    });
    return group.data;
  };

  const postCityGroup = async (
    newGroup: CityGroupCreation
  ): Promise<CityGroupsResponse> => {
    const url = CITY_GROUP.POST;
    const result = await api.postRequest(url, newGroup, {
      headers: { 'Content-Host': 'boletos-ms-v1' },
    });
    return result.data;
  };

  const patchCityGroup = async (group: CityGroup) => {
    const url = CITY_GROUP.PATCH(group.id);
    const result = await api.patchRequest(url, group, {
      headers: { 'Content-Host': 'boletos-ms-v1' },
    });
    return result;
  };
  return {
    getAllCityGroups,
    getCityGroupById,
    postCityGroup,
    patchCityGroup,
  };
};
