import associatesReport from './contexts/associatesReport';
import batchListLocales from './contexts/batchList';
import billing from './contexts/billing';
import branchFormLocales from './contexts/branchForm';
import { default as branchListLocales } from './contexts/branchList';
import cancelPlanLocales from './contexts/cancelPlan';
import cashFlows from './contexts/cashFlows';
import cashOutWithdrawals from './contexts/cashOutWithdrawals';
import cashReport from './contexts/cashReport';
import changeOwnership from './contexts/changeOwnership';
import chargeAssociate from './contexts/chargeAssociate';
import closeBoxLocales from './contexts/closeBox';
import columbarium from './contexts/columbarium';
import contractStatementReport from './contexts/contractStatementReport';
import createOnlinePaymentSlipLocales from './contexts/createOnlinePaymentSlip';
import createPartnerLocales from './contexts/createPartners';
import createPaymentSlipLocales from './contexts/createPaymentSlip';
import cremationAdditionLocales from './contexts/cremationAddition';
import deathHistoryLocales from './contexts/deadhHistory';
import deathLocales from './contexts/death';
import deathDeclarantLocales from './contexts/deathDeclarant';
import deathRecords from './contexts/deathRecords';
import deathRequiredDocuments from './contexts/deathRequiredDocuments';
import deliveryLocales from './contexts/delivery';
import deliveryManagement from './contexts/deliveryManagement';
import deliveryPdf from './contexts/deliveryPdf';
import dependentsOverviewLocales from './contexts/dependentsOverview';
import editDependentLocales from './contexts/editDependent';
import editPartnersLocales from './contexts/editPartners';
import financialLocales from './contexts/financial';
import home from './contexts/home';
import installmentsDebts from './contexts/installmentsDebts';
import loanTableLocales from './contexts/loanTable';
import loginLocales from './contexts/login';
import logoutLocales from './contexts/logout';
import managementLocales from './contexts/management';
import managementReportsLocales from './contexts/managementReports';
import membershipFeeLocales from './contexts/membershipFee';
import menuNewClient from './contexts/menuNewCliet';
import modalCityGroupPickerLocales from './contexts/modalCityGroupPicker';
import modalDeathRequiredDocuments from './contexts/modalDeathRequiredDocuments';
import modalEditPortionPrince from './contexts/modalEditPortionPrice';
import monthlyApprovalHistory from './contexts/monthlyApprovalHistory';
import negociateGracePeriodLocales from './contexts/negociateGracePeriod';
import newDependentLocales from './contexts/newDependent';
import observationsLocales from './contexts/observations';
import openBoxLocales from './contexts/openBox';
import orthopedicEquipmentLocales from './contexts/orthopedicEquipment';
import orthopedicGroupLocales from './contexts/orthopedicGroup';
import orthopedicMaintenanceLocales from './contexts/orthopedicMaintenance';
import orthopedicMovementsLocales from './contexts/orthopedicMovement';
import orthopedicSupportLocales from './contexts/orthopedicSupport';
import partnerDetails from './contexts/partnerDetails';
import partnerVaults from './contexts/partnerVaults';
import paymentMethodLocales from './contexts/paymentMethod';
import paymentSlipLocales from './contexts/paymentSlip';
import planCards from './contexts/planCard';
import postSelling from './contexts/postSelling';
import printReportNewClient from './contexts/printReportNewClient';
import printReportUpsell from './contexts/printReportUpsell';
import recoverPasswordLocales from './contexts/recoverPassword';
import reportAndResearchMenu from './contexts/reportAndResearchMenu';
import reportUpdateAssociates from './contexts/reportUpdateAssociates';
import reversalLocales from './contexts/reversal';
import salesReport from './contexts/salesReport';
import schedulePayment from './contexts/schedulePayment';
import schedulesByCollector from './contexts/schedulesByCollector';
import searchPartner from './contexts/searchPartner';
import serviceOrderLocales from './contexts/serviceOrder';
import shoppingCartLocales from './contexts/shoppingCart';
import syncCalendar from './contexts/syncCalendar';
import tuitionAdjustment from './contexts/tuitionAdjustment';
import tuitionFitApprover from './contexts/tuitionFitApprover';
import tuitionLimitsParameterization from './contexts/tuitionLimitsParameterization';
import tuitionReadjustmentsLocales from './contexts/tuitionReadjustment';
import upSell from './contexts/upSell';
import userFormLocales from './contexts/userForm';
import userListLocales from './contexts/userList';
import errorsLocales from './core/errors';
import labelsLocales from './core/labels';
import breadcrumbsLocales from './structure/breadcrumb';
import header from './structure/header';

const Locales = {
  pt: {
    ...home.pt,
    ...header.pt,
    ...breadcrumbsLocales.pt,
    ...cashFlows.pt,
    ...loginLocales.pt,
    ...openBoxLocales.pt,
    ...labelsLocales.pt,
    ...errorsLocales.pt,
    ...searchPartner.pt,
    ...partnerDetails.pt,
    ...dependentsOverviewLocales.pt,
    ...newDependentLocales.pt,
    ...installmentsDebts.pt,
    ...editDependentLocales.pt,
    ...planCards.pt,
    ...changeOwnership.pt,
    ...cancelPlanLocales.pt,
    ...paymentMethodLocales.pt,
    ...cremationAdditionLocales.pt,
    ...observationsLocales.pt,
    ...negociateGracePeriodLocales.pt,
    ...userListLocales.pt,
    ...userFormLocales.pt,
    ...editPartnersLocales.pt,
    ...reversalLocales.pt,
    ...cashOutWithdrawals.pt,
    ...closeBoxLocales.pt,
    ...shoppingCartLocales.pt,
    ...managementLocales.pt,
    ...branchListLocales.pt,
    ...branchFormLocales.pt,
    ...deliveryLocales.pt,
    ...recoverPasswordLocales.pt,
    ...cashReport.pt,
    ...syncCalendar.pt,
    ...membershipFeeLocales.pt,
    ...deathHistoryLocales.pt,
    ...logoutLocales.pt,
    ...contractStatementReport.pt,
    ...deathLocales.pt,
    ...deathDeclarantLocales.pt,
    ...deathRecords.pt,
    ...serviceOrderLocales.pt,
    ...deathRequiredDocuments.pt,
    ...modalDeathRequiredDocuments.pt,
    ...createPaymentSlipLocales.pt,
    ...createOnlinePaymentSlipLocales.pt,
    ...batchListLocales.pt,
    ...paymentSlipLocales.pt,
    ...reportUpdateAssociates.pt,
    ...deliveryPdf.pt,
    ...deliveryManagement.pt,
    ...menuNewClient.pt,
    ...printReportNewClient.pt,
    ...createPartnerLocales.pt,
    ...tuitionAdjustment.pt,
    ...tuitionLimitsParameterization.pt,
    ...tuitionFitApprover.pt,
    ...monthlyApprovalHistory.pt,
    ...tuitionReadjustmentsLocales.pt,
    ...modalCityGroupPickerLocales.pt,
    ...chargeAssociate.pt,
    ...postSelling.pt,
    ...schedulePayment.pt,
    ...schedulesByCollector.pt,
    ...reportAndResearchMenu.pt,
    ...billing.pt,
    ...modalEditPortionPrince.pt,
    ...financialLocales.pt,
    ...columbarium.pt,
    ...partnerVaults.pt,
    ...associatesReport.pt,
    ...managementReportsLocales.pt,
    ...salesReport.pt,
    ...orthopedicSupportLocales.pt,
    ...orthopedicMovementsLocales.pt,
    ...orthopedicEquipmentLocales.pt,
    ...orthopedicMaintenanceLocales.pt,
    ...loanTableLocales.pt,
    ...orthopedicGroupLocales.pt,
    ...upSell.pt,
    ...printReportUpsell.pt,
  },
} as const;

export default Locales;
export type LocalesType = typeof Locales;
