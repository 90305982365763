import styled, { css } from 'styled-components';

type ContainerProps = {
  type: 'error' | 'default';
  position?: 'top' | 'bottom';
  size?: number;
};

type PositionError = {
  position?: 'relative' | 'absolute';
};

type MessageProps = {
  fontSize: 'xs' | 'sm';
  fontWeight: 'regular' | 'semibold';
  textAlign: 'left' | 'right' | 'center';
};

export const Container = styled.div<PositionError>`
  ${({ position }) => css`
    position: ${!!position ? position : 'relative'};
  `}
`;

export const Content = styled.div<ContainerProps>`
  ${({ theme, type, position, size }) => css`
    max-width: 300px;
    margin-top: ${theme.new.spacing.sm};
    position: relative;
    display: flex;
    z-index: 1;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: ${theme.new.spacing.xs} ${theme.new.spacing.sm};
    border-radius: ${theme.new.shapes.borderRadius.sm};
    background: ${type === 'default'
      ? `${theme.new.colors.neutrals[700]}`
      : `${theme.new.colors.status[type]}`};
    ${size &&
    css`
      width: ${`${size}px`};
    `}

    ${position !== 'top' &&
    css`
      &::before {
        content: '';
        border-style: solid;
        border-color: ${type === 'default'
            ? `${theme.new.colors.neutrals[700]}`
            : `${theme.new.colors.status[type]}`}
          transparent;
        border-width: 0 6px 6px 6px;
        top: -5px;
        left: 14px;
        position: absolute;
      }
    `}

    ${position === 'top' &&
    css`
      &::before {
        content: '';
        border-style: solid;
        border-color: ${type === 'default'
            ? `${theme.new.colors.neutrals[700]}`
            : `${theme.new.colors.status[type]}`}
          transparent;
        border-width: 6px 6px 0 6px;
        bottom: -5px;
        left: 50%;
        position: absolute;
      }
    `}
  `}
`;

export const Message = styled.div<MessageProps>`
  ${({ theme, fontSize, fontWeight, textAlign }) => css`
    font-size: ${theme.new.typography.size[fontSize]};
    font-weight: ${theme.new.typography.weight[fontWeight]};
    line-height: ${theme.new.typography.lineHeight[fontSize]};
    color: ${theme.new.colors.neutrals[0]};
    text-align: ${textAlign};
  `}
`;
