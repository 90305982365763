import {
  Availability,
  BillingScheduling,
  BillingSchedulingRequest,
  getComissionReportResponse,
  ReportEletronicBilling,
  ReportEletronicBillingParams,
  ReportInformation,
} from 'types/BillingScheduling';
import { filterReport } from 'types/ReportAndInquiriesTypes';

import { PaginationMetaInfo } from 'useCases/newClients/types';

import { getURLWithParams } from 'lib/contextual/Url';

import { BILLINGS } from 'constants/endpoints';

import api from './api';

export const currentCampaign = async (
  pageParam = 1,
  filters?: filterReport,
  filterValue?: string
) => {
  const params = {
    page: pageParam,
    limit: 20,
    ...(filters && filterValue ? { [filters]: filterValue } : undefined),
  };
  const url = getURLWithParams(BILLINGS.GET_ALL_REPORT_INQUIRIES(), params);
  try {
    const result = await api.getRequest(url);
    return result?.data;
  } catch (e) {
    throw e;
  }
};

export const getBillingHistory = async (
  data: BillingSchedulingRequest
): Promise<BillingScheduling[]> => {
  const response = await api.getRequest(
    BILLINGS.BILLING_SCHEDULING(data.subscription),
    {
      params: {
        getOpenedLastWeek: data.getOpenedLastWeek,
      },
    }
  );
  return response?.data;
};

export const getMySchedules = async (
  pageParam = 1,
  startDate: string,
  endDate: string
) => {
  const params = {
    page: pageParam,
    limit: 20,
    startDate,
    endDate,
  };
  const url = getURLWithParams(BILLINGS.GET_MY_SCHEDULES, params);
  const response = await api.getRequest(url);
  return response?.data;
};

export const postComissionReport = async (
  reportInformation: ReportInformation
) => {
  const url = getURLWithParams(BILLINGS.POST_REPORT_COMISSION);
  try {
    const postResponse = await api.postRequest(url, reportInformation);
    return postResponse?.data;
  } catch (err) {
    throw err;
  }
};

export const getComissionReport = async (
  fileName: string
): Promise<getComissionReportResponse> => {
  const url = BILLINGS.GET_REPORT_COMISSION(fileName);
  try {
    const createReport = await api.getRequest(url);
    return createReport?.data;
  } catch (err) {
    throw err;
  }
};

export const getEletronicBillingReport = async (
  reportInformation: ReportEletronicBilling
) => {
  const type =
    reportInformation.type === 'csv'
      ? 'text/csv'
      : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const queryParams: ReportEletronicBillingParams = { ...reportInformation };
  if (!queryParams.associateName) delete queryParams.associateName;
  if (!queryParams.subscription) delete queryParams.subscription;
  delete queryParams.type;
  const url = getURLWithParams(
    BILLINGS.GET_REPORT_BILLING_ELETRONIC,
    queryParams
  );
  try {
    const createReport = await api.getRequest(url, {
      headers: {
        'Accept-Type': type,
      },
    });

    return { createReport, reportInformation };
  } catch (err) {
    throw err;
  }
};

export const getPaginatedUsers = async ({
  pageParam = 1,
  filterName = '',
}): Promise<{
  meta: PaginationMetaInfo;
  data: Array<{
    code: number;
    name: string;
  }>;
}> => {
  const { data } = await api.getRequest(
    getURLWithParams(BILLINGS.GET_USERS, {
      page: pageParam,
      limit: 100,
      ...(filterName !== '' && filterName.length >= 3
        ? { name: filterName }
        : undefined),
    })
  );
  return data;
};

export const patchAvailibility = async ({
  subscription,
  status,
}: Availability) => {
  const url = BILLINGS.PATCH_AVAILABILITY(subscription, status);
  try {
    const data = await api.patchRequest(url);
    return data;
  } catch (e) {
    throw e;
  }
};
