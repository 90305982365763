import { useInfiniteQuery } from 'react-query';
import {
  AreaResponse,
  City,
  Collector,
  CollectorUzoResponse,
  NeighborhoodResponse,
  Schedule,
  ScheduleOrderProps,
  SchedulesOrdersResponse,
  UzoData,
  UzoRegisters,
} from 'types/BillingAtHome';
import { getQueryString } from 'utils-general/getQueryString';

import { getURLWithParams } from 'lib/contextual/Url';

import { BILLING_AT_HOME } from 'constants/endpoints';

import api from './api';
import { getParams } from './employers_service';

export type statusOptions = 'active' | 'inactive' | '';

export type MetaPagination = {
  currentPage: number;
  itemCount: number;
  nextPage: number;
  totalItems: number;
  totalPages: number;
};

export enum ScheduleDtoStatusEnum {
  Closed = 'closed',
  Open = 'open',
  Pending = 'pending',
}

interface ScheduleDtoAgent {
  code?: string;
  name?: string;
}
export interface ScheduleDto {
  id: number;
  openDate: string;
  closeDate?: string | null;
  status: ScheduleDtoStatusEnum;
  agent: ScheduleDtoAgent;
}
interface schedulesControllerFindSchedulesType {
  data: Array<ScheduleDto>;
  meta: MetaPagination;
}

export interface ReportPresignedUrlResponse {
  url: string;
  processingStatus: 'done' | 'processing' | 'error' | 'empty';
}

export const getPaginatedCollectors = async ({
  pageParam = 1,
  limit = 1000,
  code = '',
  name = '',
  status = 'active',
  cpf = '',
}): Promise<{
  meta: MetaPagination;
  data: Array<Collector>;
}> => {
  const { data } = await api.getRequest(
    getURLWithParams(BILLING_AT_HOME.GET_COLLECTORS, {
      ...getParams({
        page: pageParam,
        limit,
        code,
        name,
        status,
        cpf,
      }),
    })
  );
  return data;
};

export const useFindAllCollectors = (name: string, whenEnabled = true) =>
  useInfiniteQuery(
    ['findAllCollectors', name],
    ({ pageParam }) => getPaginatedCollectors({ pageParam, name }),
    {
      enabled: whenEnabled,
      getNextPageParam: (lastPage) =>
        lastPage.meta.currentPage >= lastPage.meta.totalPages
          ? undefined
          : lastPage.meta.currentPage + 1,
    }
  );

const createReport = async (
  schedule: Partial<ScheduleOrderProps>,
  reportName: 'billing' | 'collectors-schedule'
) => {
  const response = await api.getRequest(
    BILLING_AT_HOME.CREATE_REPORT(reportName),
    {
      params: {
        scheduleId: schedule.scheduleId ?? undefined,
        status: schedule.status ?? undefined,
        afterOrEqualDueDate: schedule.afterOrEqualDueDate ?? undefined,
        beforeOrEqualDueDate: schedule.beforeOrEqualDueDate ?? undefined,
        agents: schedule.agents ?? undefined,
        payedFee: schedule.payedFee ?? undefined,
        orderBy: schedule.orderBy ?? undefined,
        reportType: schedule.reportType ?? 'pdf',
      },
    }
  );

  return response.data;
};

const getReportPresignedUrl = async (
  fileName: string
): Promise<ReportPresignedUrlResponse | null> => {
  try {
    const response = await api.getRequest(
      BILLING_AT_HOME.GET_REPORT_FILE_PRESIGNED_URL(fileName)
    );

    return response.data;
  } catch (error: any) {
    if (error.response?.status === 404) {
      return null;
    }

    throw error;
  }
};

export const downloadReport = async (
  schedule: Partial<ScheduleOrderProps>,
  reportName: 'billing' | 'collectors-schedule'
): Promise<ReportPresignedUrlResponse> => {
  const fileName = await createReport(schedule, reportName);
  return new Promise((resolve) => {
    const interval = setInterval(async () => {
      const response = await getReportPresignedUrl(fileName);
      if (response) {
        clearInterval(interval);
        return resolve(response);
      }
    }, 5_000);
  });
};

export const getSchedulables = async ({
  agent = '',
  openDate = 'yyyy-MM-dd',
  closeDate = 'yyyy-MM-dd',
  cities = [],
  neighborhoods = [],
}: {
  agent: string;
  openDate: string;
  closeDate: string;
  cities: number[];
  neighborhoods: string[];
}) => {
  const { data } = await api.getRequest(BILLING_AT_HOME.GET_SCHEDULABLES, {
    params: {
      agent,
      openDate,
      closeDate,
      cities,
      neighborhoods,
    },
  });
  return data;
};

export const getPaginatedCities = async ({
  pageParam = 1,
  limit = 10,
  orderBy = '',
  name = '',
}): Promise<{
  meta: MetaPagination;
  data: Array<City>;
}> => {
  const { data } = await api.getRequest(BILLING_AT_HOME.GET_CITIES_BY_NAME, {
    params: {
      page: pageParam,
      limit,
      orderBy,
      name,
    },
  });
  return data;
};

export const getPaginatedCitiesByState = async ({
  pageParam = 1,
  limit = 10,
  orderBy = '',
  state = '',
}): Promise<{
  meta: MetaPagination;
  data: Array<City>;
}> => {
  const { data } = await api.getRequest(BILLING_AT_HOME.GET_CITIS_BY_STATE, {
    params: {
      page: pageParam,
      limit,
      orderBy,
      state,
    },
  });
  return data;
};

export const createSchedule = async (data: Schedule) => {
  const response = await api.postRequest(BILLING_AT_HOME.POST_SCHEDULE, data);
  return response.data;
};

export const openSchedule = async (id: number) => {
  const response = await api.patchRequest(
    BILLING_AT_HOME.PATCH_OPEN_SCHEDULE(id)
  );
  return response.data;
};

export const schedulesOrders = async (
  schedule: Partial<ScheduleOrderProps>
): Promise<{ data: Array<SchedulesOrdersResponse> }> => {
  try {
    const data = await api.getRequest(BILLING_AT_HOME.GET_SCHEDULES_ORDERS, {
      params: {
        scheduleId: schedule.scheduleId ?? undefined,
        status: schedule.status ?? undefined,
        afterOrEqualDueDate: schedule.afterOrEqualDueDate ?? undefined,
        beforeOrEqualDueDate: schedule.beforeOrEqualDueDate ?? undefined,
        agents: schedule.agents ?? undefined,
        payedFee: schedule.payedFee ?? undefined,
        orderBy: schedule.orderBy ?? undefined,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const findPaginatedSchedules = async ({
  pageParam,
  limit,
  agent,
  afterOrEqualOpenDate,
  beforeOrEqualOpenDate,
  afterOrEqualCloseDate,
  beforeOrEqualCloseDate,
  status,
}: {
  pageParam?: number;
  limit?: number;
  agent?: string;
  afterOrEqualOpenDate?: Date;
  beforeOrEqualOpenDate?: Date;
  afterOrEqualCloseDate?: Date;
  beforeOrEqualCloseDate?: Date;
  status?: string;
}): Promise<schedulesControllerFindSchedulesType> => {
  const stringified = getQueryString({
    page: pageParam,
    limit,
    agent,
    afterOrEqualOpenDate: afterOrEqualOpenDate?.toISOString() ?? undefined,
    beforeOrEqualOpenDate: beforeOrEqualOpenDate?.toISOString() ?? undefined,
    afterOrEqualCloseDate: afterOrEqualCloseDate?.toISOString() ?? undefined,
    beforeOrEqualCloseDate: beforeOrEqualCloseDate?.toISOString() ?? undefined,
    status,
  });

  const { data } = await api.getRequest(
    `${BILLING_AT_HOME.GET_SCHEDULES}?${stringified}`
  );
  return data;
};

export const schedulesControllerFindScheduleById = async (
  id: number
): Promise<ScheduleDto> => {
  const { data } = await api.getRequest(
    BILLING_AT_HOME.GET_SCHEDULES_BY_ID(id)
  );
  return data;
};

export const closeOneDeliverySchedule = async (id: number): Promise<void> => {
  const { data } = await api.getRequest(BILLING_AT_HOME.CLOSE_ONE_SCHEDULE(id));
  return data;
};

export const openSOneDeliverychedule = async (id: number): Promise<void> => {
  const { data } = await api.getRequest(BILLING_AT_HOME.OPEN_ONE_SCHEDULE(id));
  return data;
};

export const getPaginatedNeighborhoods = async ({
  pageParam = 1,
  limit = 10,
  orderBy = '',
  name = '',
  city = 0,
}): Promise<{
  meta: MetaPagination;
  data: Array<NeighborhoodResponse>;
}> => {
  const { data } = await api.getRequest(BILLING_AT_HOME.GET_NEIGHBORHOODS, {
    params: {
      page: pageParam,
      limit,
      orderBy,
      name,
      city,
    },
  });
  return data;
};

export const getPaginatedAreas = async ({
  pageParam = 1,
  limit = 100,
  code = '',
  description = '',
  agent = '',
}): Promise<{
  code: string;
  meta: MetaPagination;
  data: Array<AreaResponse>;
}> => {
  const { data } = await api.getRequest(
    getURLWithParams(BILLING_AT_HOME.GET_AREAS, {
      ...getParams({
        page: pageParam,
        limit,
        code,
        description,
        agent,
      }),
    })
  );
  return data;
};

export const useFindAllAreas = (code: string, whenEnabled = true) =>
  useInfiniteQuery(
    ['findAllAreas', code],
    ({ pageParam }) => getPaginatedAreas({ pageParam, code }),
    {
      enabled: whenEnabled,
      getNextPageParam: (lastPage) =>
        lastPage.meta.currentPage >= lastPage.meta.totalPages
          ? undefined
          : lastPage.meta.currentPage + 1,
    }
  );

export const getCollectorsUzo = async ({
  pageParam = 1,
  limit = 1000,
  agentName = '',
}): Promise<{
  meta: MetaPagination;
  data: Array<CollectorUzoResponse>;
}> => {
  const { data } = await api.getRequest(
    getURLWithParams(BILLING_AT_HOME.GET_UZO, {
      ...getParams({
        page: pageParam,
        limit,
        agentName,
      }),
    })
  );
  return data;
};

export const synchronizeUzo = async (data: UzoData) => {
  const response = await api.patchRequest(BILLING_AT_HOME.PATCH_UZO(), data);
  return response;
};

export const collectorUzo = async (data: UzoRegisters) => {
  const response = await api.postRequest(BILLING_AT_HOME.POST_UZO, data);
  return response.data;
};
