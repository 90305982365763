import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useFindAllUsers } from 'useCases/billings/useFindAllUsers';

import SearchSelect from 'components/shared/forms/SearchSelect';

interface SelectUserProps {
  fieldValue: string;
  setFieldValue: (value: string) => void;
  fieldTouched?: boolean;
  fieldError?: string;
  required?: boolean;
}

const SelectUser = ({
  fieldValue,
  setFieldValue,
  fieldTouched,
  fieldError,
  required = false,
}: SelectUserProps) => {
  const intl = useIntl();

  const [search, setSearch] = useState('');

  const {
    allUsers,
    isLoading: isLoadingSellers,
    refetch: refetchSellers,
    fetchNextPage: fetchNextPageSellers,
    hasNextPage: hasNextPageSellers,
    error: errorSellers,
  } = useFindAllUsers(search, !search);

  const debouncedSearchSellers = useCallback(debounce(refetchSellers, 800), []);

  const optionsFieldsSellers = useMemo(
    () =>
      allUsers?.pages
        .flatMap(({ data }) => data)
        .map((item) => ({
          label: `(${item.code}) - ${item.name}`,
          value: item.code,
        })) ?? [{ label: '', value: '' }],
    [allUsers]
  );

  const isOptionsFullFilled =
    allUsers?.pages[0].meta.totalPages === allUsers?.pageParams.length;

  const setSearchValue = (value: string) => {
    if (isOptionsFullFilled) {
      return;
    }

    if (value.length >= 3 && value !== search) {
      setSearch(value);
      debouncedSearchSellers();
    }

    if (fieldValue !== '') {
      setFieldValue('');
    }
  };

  const handlePagination = () => {
    if (!isLoadingSellers) {
      fetchNextPageSellers();
    }
  };

  return (
    <SearchSelect
      required={required}
      id="user"
      label={intl.formatMessage({
        id: 'select.collector',
      })}
      placeholder={intl.formatMessage({
        id: 'collectionCommissionReport.selectLabel',
      })}
      options={optionsFieldsSellers}
      value={fieldValue}
      isLoading={isLoadingSellers}
      hasErrors={fieldTouched && Boolean(fieldError)}
      errorMessage={fieldError}
      onSearchChange={(value: string | number) => {
        setSearchValue(String(value));
      }}
      setValue={(_, value) => setFieldValue(String(value))}
      cancelSearch={() => {
        setSearch('');
      }}
      handlePagination={handlePagination}
      hasPagination={hasNextPageSellers}
      hasErrorPagination={!!errorSellers}
    />
  );
};

export default SelectUser;
