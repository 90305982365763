import ComponentGate from 'permissions/ComponentGate';
import { Fragment, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import { useLogout } from 'useCases/auth/useLogout';
import { DtoTuitionReadjusmentProps } from 'useCases/tuitionReadjustment/types';

import CacheControl from 'lib/core/CacheControl';

import { useSession } from 'contexts/AuthContext';
import { useCart } from 'contexts/CartContext';
import { useNotification } from 'contexts/NotificationContext';

import Avatar from 'components/shared/core/avatar';
import Skeleton from 'components/shared/newCore/Skeleton';

import {
  ComponentsPermissions,
  readjustmentNotificationTypeEnum,
} from 'constants/enums';
import { Urls } from 'constants/urls';

import { ReactComponent as Bell } from 'assets/images/Bell.svg';
import { ReactComponent as Cart } from 'assets/images/Cart.svg';
import { ReactComponent as ClosedEye } from 'assets/images/EyeClosed.svg';
import { ReactComponent as Eye } from 'assets/images/EyeOpen.svg';
import { ReactComponent as Trash } from 'assets/images/Trash.svg';
import { ReactComponent as Exit } from 'assets/images/exit.svg';
import LogoSlogan from 'assets/images/logoSloganCorte.png';
import UserDefault from 'assets/images/user-default.png';

import * as S from './HomeLayout.styles';

interface HomeLayoutProps {
  children?: ReactNode | ReactNode[];
}

const HomeLayout = ({ children }: HomeLayoutProps) => {
  const intl = useIntl();
  const history = useNavigate();
  const [showCart, setShowCart] = useState(false);
  const [showBell, setShowBell] = useState(false);
  const { user, logout } = useSession();
  const { cartLength, carts, isCartLoading } = useCart();
  const [modalTuitionReadjustmentProps, setModalTuitionReadjusmentProps] =
    useState<DtoTuitionReadjusmentProps>({
      readjustmentSolicitation: {},
      refresh: false,
    });

  const {
    notificationLenght,
    notifications,
    updateMessages,
    isNotificationLoading,
    current,
  } = useNotification();
  const { handlelogout } = useLogout();
  const handleOnExit = () => {
    current.disconnect();
    CacheControl.dropAllCaches();
    handlelogout({ email: user.email });
    logout();
    history(Urls.Login);
  };

  const handleClickCartItem = (subscriptionCode: string) => {
    setShowCart(false);
    window.location.href = Urls.ShoppingCart.replace(
      ':id',
      subscriptionCode.toString()
    );
  };

  return (
    <S.Container>
      <S.Header>
        <S.Logo src={LogoSlogan} alt="Zelo logo" />
        <S.AsideHeader>
          <S.Profile>
            <article>
              <p>
                {intl.formatMessage({ id: 'header.greetings' })}
                <br />
                <span>{user.name}</span>
              </p>
            </article>
            <figure>
              <Avatar src={user.avatar || UserDefault} alt={user.name} />
            </figure>
          </S.Profile>

          <S.CartWrapper
            open={showCart}
            onOpenChange={(show) => setShowCart(show)}
          >
            <S.PopoverTrigger $isLoading={false}>
              <Cart />
              {isCartLoading && (
                <Skeleton
                  height="100%"
                  width="100%"
                  style={{ borderRadius: '999px' }}
                />
              )}
              {cartLength > 0 && <span>{cartLength}</span>}
            </S.PopoverTrigger>
            <S.PopoverContent>
              {isCartLoading ? (
                <ul>
                  <Skeleton width="200px" height="30px" />
                  <Skeleton width="200px" height="30px" />
                  <Skeleton width="200px" height="30px" />
                </ul>
              ) : cartLength > 0 ? (
                <ul>
                  {carts.map(
                    (
                      {
                        id,
                        subscriptionCode,
                        name,
                        socialSecurityNumber,
                        quantityItems,
                      },
                      index
                    ) => (
                      <Fragment key={id}>
                        <S.CardItem
                          onClick={() => handleClickCartItem(subscriptionCode)}
                        >
                          <div>
                            <p>{name}</p>
                            <p>{socialSecurityNumber}</p>
                          </div>
                          <div>
                            <Cart />
                            <span>{quantityItems}</span>
                          </div>
                        </S.CardItem>
                        {index !== cartLength - 1 && <S.ItemDivider />}
                      </Fragment>
                    )
                  )}
                </ul>
              ) : (
                <S.EmptyCartMessage>
                  {intl.formatMessage({ id: 'header.emptyCart' })}
                </S.EmptyCartMessage>
              )}
            </S.PopoverContent>
          </S.CartWrapper>

          <ComponentGate
            permission={ComponentsPermissions.HOME_CARDS_ADJUSTMENT_TUITION}
          >
            <S.BellWrapper
              open={showBell}
              onOpenChange={(show) => setShowBell(show)}
            >
              <S.PopoverTrigger $isLoading={isCartLoading}>
                <Bell />
                {isNotificationLoading && (
                  <Skeleton
                    height="100%"
                    width="100%"
                    style={{ borderRadius: '999px' }}
                  />
                )}
                {notificationLenght > 0 && <span>{notificationLenght}</span>}
              </S.PopoverTrigger>
              <S.PopoverContent>
                {isNotificationLoading ? (
                  <ul>
                    <Skeleton width="200px" height="30px" />
                    <Skeleton width="200px" height="30px" />
                    <Skeleton width="200px" height="30px" />
                  </ul>
                ) : notificationLenght > 0 ? (
                  <ul>
                    {notifications.map(
                      (
                        content: {
                          id: string;
                          title: string;
                          status: number | string;
                          description: string;
                          referenceId: string;
                        },
                        idx: number
                      ) => (
                        <Fragment key={content.id}>
                          <S.NotificationItem>
                            <Link
                              style={{ textDecoration: 'none' }}
                              state={{ isNotification: true }}
                              to={
                                content.status === 0
                                  ? Urls.monthlyApprovalHistoryRemoteRequirementAdjusment.replace(
                                      ':id',
                                      content.referenceId
                                    )
                                  : '#'
                              }
                            >
                              <div>
                                <p>
                                  {
                                    readjustmentNotificationTypeEnum[
                                      content.title
                                    ]
                                  }
                                </p>
                                <p>{content.description}</p>
                              </div>
                            </Link>
                            <div>
                              {content.status === 0 && (
                                <Link
                                  state={{ isNotification: true }}
                                  to={Urls.monthlyApprovalHistoryRemoteRequirementAdjusment.replace(
                                    ':id',
                                    content.referenceId
                                  )}
                                >
                                  <Eye
                                    onClick={() => {
                                      current.emit('read-message', {
                                        idMessage: content.id,
                                      });

                                      updateMessages();
                                    }}
                                    style={{ marginRight: '4px' }}
                                  />
                                </Link>
                              )}
                              {content.status !== 0 && (
                                <ClosedEye style={{ marginRight: '4px' }} />
                              )}
                              <Trash
                                onClick={() => {
                                  current.emit('delete-message', {
                                    idMessage: content.id,
                                  });
                                  updateMessages();
                                }}
                              />
                            </div>
                          </S.NotificationItem>
                          {idx !== notificationLenght - 1 && <S.ItemDivider />}
                        </Fragment>
                      )
                    )}
                  </ul>
                ) : (
                  <S.EmptyCartMessage>
                    {intl.formatMessage({ id: 'header.emptyNotification' })}
                  </S.EmptyCartMessage>
                )}
              </S.PopoverContent>
            </S.BellWrapper>
          </ComponentGate>
          <S.Actions>
            <S.Link onClick={handleOnExit}>
              {intl.formatMessage({ id: 'header.exit' })}
              <Exit />
            </S.Link>
          </S.Actions>
        </S.AsideHeader>
      </S.Header>
      <S.Content>
        <Outlet
          context={[
            modalTuitionReadjustmentProps,
            setModalTuitionReadjusmentProps,
          ]}
        />
      </S.Content>
    </S.Container>
  );
};

export default HomeLayout;
