import 'react-loading-skeleton/dist/skeleton.css';
import { createGlobalStyle, css } from 'styled-components';

import Eina02Bold from 'assets/fonts/Eina02/Eina02-Bold.otf';
import Eina02BoldItalic from 'assets/fonts/Eina02/Eina02-BoldItalic.otf';
import Eina02Regular from 'assets/fonts/Eina02/Eina02-Regular.otf';
import Eina02RegularItalic from 'assets/fonts/Eina02/Eina02-RegularItalic.otf';
import Eina02SemiBold from 'assets/fonts/Eina02/Eina02-SemiBold.otf';

export const CssReset = createGlobalStyle`
  ${({ theme }) => css`
    *,
    *::before,
    *::after {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      font-family: ${theme.new.typography.family.primary};
      outline: none;
    }
    ::-webkit-scrollbar {
      width: 10px;
      height: 12px;
    }

    ::-webkit-scrollbar-track {
      background: rgba(145, 145, 183, 0.3);
      border-radius: 100px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(145, 145, 183, 0.5);
      border-radius: 100px;
      border: 4px solid rgba(0, 0, 0, 0);
    }

    @font-face {
      font-family: ${theme.new.typography.family.primary};
      src: url(${Eina02Regular});
      font-style: normal;
      font-weight: 400;
    }

    @font-face {
      font-family: ${theme.new.typography.family.primary};
      src: url(${Eina02RegularItalic});
      font-style: italic;
      font-weight: 400;
    }

    @font-face {
      font-family: ${theme.new.typography.family.primary};
      src: url(${Eina02Bold});
      font-style: normal;
      font-weight: bold;
    }

    @font-face {
      font-family: ${theme.new.typography.family.primary};
      src: url(${Eina02BoldItalic});
      font-style: italic;
      font-weight: bold;
    }

    @font-face {
      font-family: ${theme.new.typography.family.primary};
      src: url(${Eina02SemiBold});
      font-style: normal;
      font-weight: 600;
    }
  `};

  body {
    background-color: hsla(235, 32%, 93%, 1);
  }
`;
