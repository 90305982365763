export default function downloadBlobPDF(file: File, filename: string) {
  var fileURL = URL.createObjectURL(file);

  const anchor = document.createElement('a');
  anchor.href = fileURL;
  anchor.download = `${filename}.pdf`;
  anchor.click();

  URL.revokeObjectURL(fileURL);
}
