import styled, { css } from 'styled-components';

import { device } from 'styles/tokens/devices';

interface RowProps {
  disabled?: boolean;
  screenXs?: boolean;
  minorRowPadding?: boolean;
}

const Row = styled.div<RowProps>`
  ${({ theme, disabled, minorRowPadding }) => css`
    background-color: ${theme.new.colors.neutrals[0]};
    display: flex;
    ${minorRowPadding
      ? css`
          padding: ${theme.new.spacing.sm} ${theme.new.spacing.md};
        `
      : css`
          padding: ${theme.new.spacing.lg} ${theme.new.spacing.xl};
        `};
    border-radius: ${theme.new.shapes.borderRadius.lg};
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: ${theme.new.spacing.xs};
    overflow-x: none;
    ${disabled &&
    css`
      opacity: ${theme.new.effects.opacity.xs};
    `};
  `};
  ${(props) =>
    props.screenXs &&
    css`
      @media ${device.tabletL} {
        flex-direction: column;
        align-items: flex-start;
        padding: 0.5rem 0.8rem;
        button {
          width: 100%;
        }
      }
    `}
`;

export default Row;
