import * as AlertDialog from '@radix-ui/react-alert-dialog';
import styled, { css, Keyframes, keyframes } from 'styled-components';

const modalToValue = '-50%, -50%, 0';
const modalFromValue = '-50%, 0, 0';
const contentFromValue = '0, 50%, 0';
const contentToValue = '0, 0, 0';

const animationFrame = (isModal: boolean, close: boolean) =>
  close
    ? keyframes`
  0% {
    opacity: 1;
    -webkit-transform: translate3d(${isModal ? modalToValue : contentToValue});
    transform: translate3d(${isModal ? modalToValue : contentToValue});
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(${
      isModal ? modalFromValue : contentFromValue
    });
    transform: translate3d(${isModal ? modalFromValue : contentFromValue});
  }
`
    : keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translate3d(${
      isModal ? modalFromValue : contentFromValue
    });
    transform: translate3d(${isModal ? modalFromValue : contentFromValue});
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(${isModal ? modalToValue : contentToValue});
    transform: translate3d(${isModal ? modalToValue : contentToValue});
  }
`;

const openAnimation = (animation: Keyframes) => css`
  animation: ${animation} 0.3s ease-in-out 0s forwards;
  -webkit-animation: ${animation} 0.3s ease-in-out 0s forwards;
`;

const closeAnimation = (animation: Keyframes) => css`
  animation: ${animation} 0.3s ease-out 0.05s forwards;
  -webkit-animation: ${animation} 0.3s ease-out 0.05s forwards;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Overlay = styled(AlertDialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Content = styled(AlertDialog.Content)<{ $isClosing: boolean }>`
  ${({ theme, $isClosing }) => css`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 558px;
    height: 361px;
    background-color: #fff;
    border-radius: ${theme.borderRadius.md};
    box-shadow: ${theme.elevation.secondary};
    display: flex;
    opacity: 0;
    ${$isClosing
      ? openAnimation(animationFrame(true, $isClosing))
      : closeAnimation(animationFrame(true, $isClosing))};
  `}

  @media (max-width: 767px) {
    width: 90%;
  }
`;

export const Header = styled.header<{ $isClosing: boolean }>`
  ${({ theme, $isClosing }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: ${theme.spacing.sm};
    flex: 1;
    ${$isClosing
      ? openAnimation(animationFrame(false, $isClosing))
      : closeAnimation(animationFrame(false, $isClosing))};
  `}
`;

export const Title = styled(AlertDialog.Title)<{
  $hasMessage: boolean;
  $isClosing: boolean;
}>`
  ${({ $hasMessage, theme, $isClosing }) => css`
    text-align: center;
    margin-top: ${theme.new.spacing.xl};
    color: ${theme.new.colors.primary.main};
    font-size: ${theme.new.typography.size[$hasMessage ? 'xl2' : 'xl3']};
    line-height: ${theme.new.typography.lineHeight[
      $hasMessage ? 'xl2' : 'xl3'
    ]};
    font-weight: ${theme.new.typography.weight.semibold};
    opacity: 0;

    ${$isClosing
      ? openAnimation(animationFrame(false, $isClosing))
      : closeAnimation(animationFrame(false, $isClosing))};
  `}
`;

export const Description = styled(AlertDialog.Description)<{
  $isClosing: boolean;
  $sizeMessage: string | undefined;
}>`
  ${({ theme, $isClosing, $sizeMessage }) => css`
    margin-top: ${theme.new.spacing.sm};
    color: ${theme.new.colors.neutrals['900']};
    font-size: ${$sizeMessage && $sizeMessage === 'midium'
      ? theme.new.typography.size.default
      : theme.new.typography.size.xs};
    line-height: ${theme.new.typography.lineHeight.xs};
    ${$isClosing
      ? openAnimation(animationFrame(false, $isClosing))
      : closeAnimation(animationFrame(false, $isClosing))};
  `}
`;

export const Loading = styled.span`
  ${({ theme }) => css`
    width: 25px;
    height: 25px;
    border-radius: ${theme.new.shapes.borderRadius.full};
    border: ${theme.new.shapes.borderWidth.xl} solid
      ${theme.new.colors.secondary.dark};
    border-bottom: ${theme.new.shapes.borderWidth.xl} solid transparent;
    margin-top: ${theme.new.spacing.xl};
    -webkit-animation: ${spin} 1s linear infinite;
    animation: ${spin} 1s linear infinite;
  `}
`;

export const Close = styled.span`
  position: fixed;
  top: 40px;
  right: 40px;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
  }
`;
