import { useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

import InfiniteScroll from 'components/shared/core/infiniteScroll';
import LoadingSpinner from 'components/shared/core/loadingSpinner';
import Field from 'components/shared/forms/Field';
import Tooltip from 'components/shared/newCore/Tooltip';

import { ReactComponent as CancelGray } from 'assets/images/CancelGray.svg';
import { ReactComponent as MagnifyingGlassGray } from 'assets/images/MagnifyingGlassGray.svg';

import * as S from './CustomSelect.styles';

export interface SelectItem {
  label: any;
  value: any;
  disabled?: boolean;
}
interface CustomSelectProps {
  label: string;
  options: SelectItem[];
  inputName: string;
  inputValue: string | number | boolean;
  placeholder?: string;
  isBordered?: boolean;
  disabled?: boolean;
  borderBackgroudWhite?: boolean;
  hasErrors?: boolean;
  helperText?: string;
  helperTextPosition?: 'top' | 'bottom';
  className?: string;
  onBlur?: () => void;
  setValue: (label: any, value: any) => void;
  id?: string;
  labelValue?: string;
  size?: number;
  isLoading?: boolean;
  required?: boolean;
  hasOptionHidden?: (option: any) => void;
  handlePagination?: () => void;
  hasPagination?: boolean;
  hasErrorPagination?: boolean;
  filterInput?: boolean;
  filterInputPlaceholder?: string;
  setValueFilter?: (value: string) => void;
  valueFilterInput?: string;
  sendFilter?: boolean;
  cancelFilter?: () => void;
  sizeLoading?: number;
  minWidth?: number;
  backdropSettings?: {
    fullWidth: boolean;
    fullHeight: boolean;
  };
  isAccordionForm?: boolean;
  darkBorder?: boolean;
  hasIconButton?: boolean;
  IconButton?: React.ReactNode;
  handleIconButton?: (value: string) => void;
  shadowColor?: 'light' | 'dark';
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

const CustomSelect = ({
  label,
  options,
  inputName,
  inputValue,
  placeholder,
  isBordered,
  disabled = false,
  borderBackgroudWhite,
  hasErrors,
  helperText,
  helperTextPosition = 'bottom',
  className,
  id,
  onBlur,
  setValue,
  labelValue,
  size,
  isLoading,
  required,
  hasOptionHidden,
  handlePagination,
  hasPagination,
  hasErrorPagination,
  filterInput,
  filterInputPlaceholder,
  setValueFilter,
  valueFilterInput,
  sendFilter,
  cancelFilter,
  sizeLoading,
  minWidth,
  backdropSettings = {
    fullHeight: true,
    fullWidth: true,
  },
  isAccordionForm,
  darkBorder,
  hasIconButton,
  IconButton,
  handleIconButton,
  onKeyDown,
  shadowColor = 'light',
}: CustomSelectProps) => {
  const [isActived, setIsActived] = useState(false);
  const [selected, setSelected] = useState<SelectItem>({} as SelectItem);

  const handleSelectItem = (item: SelectItem) => {
    if (onBlur) onBlur();
    setSelected(item);
    setIsActived(false);
    setValue(inputName, item.value);
  };

  const handleSelectButtonItem = (item: SelectItem) => {
    hasIconButton && handleIconButton && handleIconButton(item.value);
    setIsActived(false);
  };

  useEffect(() => {
    const optionsFiltereds = options.filter((i) => i.value === inputValue);

    if (inputValue === '') setSelected({} as SelectItem);
    else {
      if (optionsFiltereds.length > 0) {
        setSelected(optionsFiltereds[0]);
      } else {
        setSelected({
          label: labelValue ? labelValue : inputValue,
          value: inputValue,
        });
      }
    }
  }, [inputValue, options, labelValue]);

  return (
    <>
      <S.Wrap
        disabled={disabled}
        borderBackgroudWhite={borderBackgroudWhite}
        isAccordionForm={isAccordionForm}
      >
        {hasErrors && helperText && helperTextPosition === 'top' && (
          <Tooltip position="top" type="error" message={helperText as string} />
        )}
        <S.LabelSelect isBordered={isBordered}>
          {required && <span>*</span>} {label}
        </S.LabelSelect>

        {/*  why did that strange loading was used? */}
        <S.Container
          className={className}
          size={size}
          minWidth={minWidth}
          error={hasErrors}
        >
          <S.SelectInput
            isDisabled={disabled}
            isFocused={isActived}
            isBordered={isBordered ? true : false}
            darkBorder={darkBorder ? true : false}
            placeholder={placeholder || selected.label}
            onClick={() => setIsActived(!isActived)}
            id={id}
            isAccordionForm={isAccordionForm}
          >
            <S.SpanSelectInput
              isSelected={!selected.label}
              status={
                typeof selected.value === 'number'
                  ? selected.label
                  : selected.value
              }
            >
              {!selected.label ? placeholder : selected.label}
            </S.SpanSelectInput>
            <FaChevronDown />
          </S.SelectInput>
          {isActived && !disabled && (
            <>
              <S.Backdrop
                fullHeight={backdropSettings.fullHeight}
                fullWidth={backdropSettings.fullWidth}
                onClick={() => {
                  setIsActived(false);
                  if (onBlur) onBlur();
                }}
              ></S.Backdrop>
              <S.Options shadowColor={shadowColor}>
                {filterInput && (
                  <S.FilterArea>
                    <Field
                      variant="bordered"
                      type="text"
                      label=""
                      value={valueFilterInput ? valueFilterInput : ''}
                      placeholder={filterInputPlaceholder}
                      endAdornment={
                        valueFilterInput && valueFilterInput.length === 0 ? (
                          <MagnifyingGlassGray />
                        ) : (
                          <>
                            {sendFilter &&
                            valueFilterInput &&
                            valueFilterInput.length > 0 ? (
                              <LoadingSpinner
                                width={20}
                                height={20}
                                borderSize={3}
                              />
                            ) : (
                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => cancelFilter && cancelFilter()}
                              >
                                <CancelGray />
                              </div>
                            )}
                          </>
                        )
                      }
                      onKeyDown={onKeyDown}
                      onChange={(e) =>
                        setValueFilter && setValueFilter(e.target.value)
                      }
                    />
                  </S.FilterArea>
                )}
                <S.OptionsList>
                  {options.map((item) => {
                    const isHidden = hasOptionHidden?.(item) || false;
                    return (
                      <S.OptionsListItem
                        hidden={isHidden}
                        key={item.value}
                        onClick={() => handleSelectItem(item)}
                      >
                        <span>{item.label}</span>
                        {hasIconButton ? (
                          <button
                            type="button"
                            onClick={() => handleSelectButtonItem(item)}
                          >
                            {IconButton}
                          </button>
                        ) : null}
                      </S.OptionsListItem>
                    );
                  })}
                  {!hasErrorPagination && handlePagination && hasPagination && (
                    <>
                      <InfiniteScroll
                        loadMore={() => handlePagination()}
                        refresh={handlePagination}
                      />
                      <LoadingSpinner width={20} height={20} borderSize={4} />
                    </>
                  )}
                </S.OptionsList>
              </S.Options>
            </>
          )}
        </S.Container>
        {hasErrors && helperText && helperTextPosition === 'bottom' && (
          <Tooltip type="error" message={helperText as string} />
        )}
      </S.Wrap>
    </>
  );
};

export default CustomSelect;
