import React, { ChangeEvent, useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import Tooltip from 'components/shared/newCore/Tooltip';

import * as S from './Select.styles';

export interface SelectItem {
  label: string;
  value: any;
  disabled?: boolean;
}

interface SelectProps {
  options: SelectItem[];
  onChange: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLSelectElement>;
  value: any;
  defaultValue?: any;
  label: string;
  id?: string;
  name?: string;
  placeholder?: string;
  hasErrors?: boolean;
  helperText?: string;
  className?: string;
  hasBorder?: boolean;
  required?: boolean;
  testid?: string;
  disabled?: boolean;
  hasOptionHidden?: (option: any) => void;
  isLoading?: boolean;
  isAccordionRegistrationDataForm?: boolean;
}

const Select = ({
  options,
  onChange,
  value,
  label,
  id,
  name,
  placeholder,
  hasErrors,
  helperText,
  className,
  hasBorder,
  required,
  disabled,
  hasOptionHidden,
  isLoading,
  defaultValue = null,
  isAccordionRegistrationDataForm,
}: SelectProps) => {
  const [selection, setSelection] = useState(value);
  const [focused, setFocused] = useState(false);

  useEffect(() => setSelection(value), [value]);

  const handleBlur = () => {
    setFocused(false);
  };

  const handleClick = () => {
    setFocused(true);
  };

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange && onChange(event);
    setSelection(event.target.value);
  };

  return (
    <S.Container
      isAccordionRegistrationDataForm={isAccordionRegistrationDataForm}
      disabled={disabled}
    >
      <S.Label htmlFor={id}>
        {required && <span>*</span>} {label}
      </S.Label>
      {isLoading ? (
        <S.SkeletonWrapper />
      ) : (
        <S.Content error={hasErrors} border={hasBorder}>
          <S.Icon>{focused ? <FiChevronUp /> : <FiChevronDown />}</S.Icon>
          <S.Input
            disabled={disabled}
            id={id}
            onClick={handleClick}
            onBlur={handleBlur}
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            value={selection}
            defaultValue={defaultValue ?? undefined}
            className={className}
          >
            <S.DefaultOption value="" disabled hidden>
              {placeholder}
            </S.DefaultOption>
            {options.map((option, i) => {
              const isHidden = hasOptionHidden?.(option) || false;
              return (
                <S.Option
                  hidden={isHidden}
                  key={`${option.value}${i}`}
                  value={option.value}
                >
                  {option.label}
                </S.Option>
              );
            })}
          </S.Input>
        </S.Content>
      )}
      {hasErrors && <Tooltip type="error" message={helperText as string} />}
    </S.Container>
  );
};

Select.defaultProps = {
  placehoulder: '',
};

export default Select;
