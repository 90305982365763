import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { BreadcrumbLink } from 'components/shared';
import NavigationCard from 'components/shared/core/navigationCard';
import Breadcrumb from 'components/shared/newCore/Breadcrumb';

import { Urls } from 'constants/urls';

import commission from 'assets/images/commission.svg';
import contactBook from 'assets/images/contact-book.svg';
import link from 'assets/images/link.svg';
import schedule from 'assets/images/schedule2.svg';

import * as G from './../../styles/themes/global/mainContainer';
import * as S from './styles';

const ReportAndResearchMenu = () => {
  const intl = useIntl();
  const breadcrumbLinks: BreadcrumbLink[] = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'breadcrumb.homePage' }),
        redirectTo: Urls.HomePage,
      },
      {
        label: intl.formatMessage({ id: 'breadcrumb.chargeAssociate' }),
        redirectTo: Urls.associateChargeMenu,
      },
      {
        label: intl.formatMessage({ id: 'breadcrumb.reportAndResearchMenu' }),
        redirectTo: Urls.reportAndResearchMenu,
      },
    ],
    [intl]
  );
  return (
    <S.MenuConainer>
      <S.TitleContainer>
        <Breadcrumb path={breadcrumbLinks} />
        <G.Nav>
          <header>
            <G.Title>
              {intl.formatMessage({ id: 'reportAndResearchMenu.title' })}
            </G.Title>
          </header>
          <S.Subtitle>
            {intl.formatMessage({ id: 'paymentSlip.menu.subtitle' })}
          </S.Subtitle>
        </G.Nav>
      </S.TitleContainer>

      <S.CardsWrapper>
        <NavigationCard
          icon={schedule}
          redirectUrl={Urls.currentCampaign}
          alt={intl.formatMessage({ id: 'reportAndResearchMenu.card1' })}
          contentText={'reportAndResearchMenu.card1'}
        />
        <NavigationCard
          icon={contactBook}
          redirectUrl={Urls.findMySchedules}
          alt={intl.formatMessage({ id: 'reportAndResearchMenu.card2' })}
          contentText={'reportAndResearchMenu.card2'}
        />
        <NavigationCard
          icon={commission}
          redirectUrl={Urls.collectionCommissionReport}
          alt={intl.formatMessage({ id: 'reportAndResearchMenu.card3' })}
          contentText={'reportAndResearchMenu.card3'}
        />
        <NavigationCard
          icon={link}
          redirectUrl={Urls.electronicBillingReport}
          alt={intl.formatMessage({ id: 'reportAndResearchMenu.card4' })}
          contentText={'reportAndResearchMenu.card4'}
        />
      </S.CardsWrapper>
    </S.MenuConainer>
  );
};

export default ReportAndResearchMenu;
