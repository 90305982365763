import { useFormik } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  OrthopedicEquipmentParams,
  OrthopedicEquipmentSearchType,
} from 'types/EquipmentMovement';
import { EquipmentFilterFormProps } from 'types/Orthopedic';

import { useFindAllEquipmentGroups } from 'useCases/orthopedic';

import { useConstants } from 'pages/orthopedicEquipment/constants';
import { useOrthopedicEquipmentValidation } from 'pages/orthopedicEquipment/useOrthopedicEquipment';

import { Grid, Row } from 'components/shared';
import ButtonLink from 'components/shared/forms/ButtonLink';
import Input from 'components/shared/forms/Input';
import SearchSelect, {
  SelectOption,
} from 'components/shared/forms/SearchSelect';
import Button from 'components/shared/newForms/Button';

import { EquipmentStatusEnumTypes } from 'constants/enums';

import { ReactComponent as GlassSearch } from 'assets/images/MagnifyingGlass.svg';

import * as S from './orthopedicEquipmentFilter.styles';

interface OrthopedicEquipmentFilterProps {
  setFilterQuery: Dispatch<SetStateAction<OrthopedicEquipmentParams>>;
  setEquipmentGroupId: Dispatch<SetStateAction<string | undefined>>;
  equipmentsByGroupLength: number;
}

const OrthopedicEquipmentFilter = ({
  setEquipmentGroupId,
  setFilterQuery,
}: OrthopedicEquipmentFilterProps) => {
  const intl = useIntl();
  const { equipmentStatusOptions, searchForEquipments } = useConstants();
  const searchTypeInitialValue = searchForEquipments[0].value;
  const [searchType, setSearchType] = useState<OrthopedicEquipmentSearchType>(
    searchTypeInitialValue
  );

  const {
    equipmentGroups,
    hasNextPage: hasNextPageEquipmentGroups,
    fetchNextPage: fetchNextPageGroups,
    isLoading: isLoadingEquipmentGroups,
    error: errorEquipmentGroups,
  } = useFindAllEquipmentGroups({
    limit: 100,
  });

  const validationSchema = useOrthopedicEquipmentValidation();
  const formik = useFormik<EquipmentFilterFormProps>({
    initialValues: {
      equipmentGroup: '',
      freeDays: '',
      description: '',
      status: '',
      assetNumber: '',
    },
    validationSchema,
    onSubmit: () => {
      setFilterQuery((prev) => ({
        ...prev,
        ...filterValues,
      }));
    },
    onReset: () => {
      setSearchType(searchTypeInitialValue);
    },
  });

  const groupEquipment: SelectOption[] = equipmentGroups?.pages[0]?.items
    ? equipmentGroups?.pages[0].items?.map((group) => ({
        label: group.description,
        value: group.id,
      }))
    : ([] as SelectOption[]);

  const handlePaginationEquipmentGroups = () => {
    if (!isLoadingEquipmentGroups) {
      fetchNextPageGroups();
    }
  };

  const handleOnPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      formik.handleSubmit();
    }
  };

  const filterValues = {
    limit: 100,
    ...(formik.values.description !== '' && {
      term: formik.values.description,
    }),
    ...(formik.values.status !== '' && {
      status: formik.values.status as EquipmentStatusEnumTypes,
    }),
    ...(Number(formik.values.assetNumber) !== 0 && {
      id: Number(formik.values.assetNumber),
    }),
    ...(formik.values.equipmentGroup !== '' && {
      equipmentGroup: formik.values.equipmentGroup,
    }),
  };

  const handleGroupChange = (value?: string) => {
    formik.setFieldValue('equipmentGroup', value);

    const group = equipmentGroups?.pages[0].items?.find(
      (group) => group.id === value
    );
    formik.setFieldValue('freeDays', group?.freeDays);
    setEquipmentGroupId(value);
  };

  const resetFilterQuery = () => {
    setFilterQuery((prev) => ({
      limit: 100,
      ...(formik.values.equipmentGroup !== '' && {
        equipmentGroup: formik.values.equipmentGroup,
      }),
    }));
    formik.setValues((prev) => ({
      ...prev,
      description: '',
      status: '',
      assetNumber: '',
    }));
  };

  const renderSearchOptions = () => {
    switch (searchType) {
      case 'description':
        return (
          <Row span="5">
            <Input
              type="text"
              id="description"
              name="description"
              variant={'lighter'}
              value={formik.values.description}
              onChange={({ target: { value } }) => {
                formik.setFieldValue('description', value);
                if (value === '') {
                  resetFilterQuery();
                }
              }}
              label={intl.formatMessage({
                id: 'orthopedicEquipment.searchFor.description',
              })}
              onBlur={formik.handleBlur}
              hasErrors={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={String(formik.errors.description)}
              onKeyPress={handleOnPressEnter}
            />
          </Row>
        );
      case 'status':
        return (
          <Row span="5">
            <SearchSelect
              id="status"
              value={formik.values.status}
              setValue={(_label, value) => {
                formik.setFieldValue('status', value);
              }}
              label={intl.formatMessage({
                id: 'orthopedicEquipment.searchFor.status',
              })}
              options={equipmentStatusOptions}
              cancelSearch={() => {
                formik.setFieldValue('status', '');
              }}
              onPressEnter={() => {
                formik.handleSubmit();
              }}
            />
          </Row>
        );
      case 'assetNumber':
        return (
          <Row span="5">
            <Input
              id="assetNumber"
              name="assetNumber"
              variant={'lighter'}
              value={formik.values.assetNumber}
              onChange={({ target: { value } }) => {
                const numericValue = value.replace(/\D/g, '');
                formik.setFieldValue('assetNumber', numericValue);
                if (value === '') {
                  resetFilterQuery();
                }
              }}
              label={intl.formatMessage({
                id: 'orthopedicEquipment.optionsAssetNumber',
              })}
              onKeyPress={handleOnPressEnter}
            />
          </Row>
        );
      default:
        return null;
    }
  };

  const resetFilterValues = (searchType: OrthopedicEquipmentSearchType) => {
    switch (searchType) {
      case 'description':
        formik.setFieldValue('status', '');
        formik.setFieldValue('assetNumber', '');
        break;
      case 'status':
        formik.setFieldValue('description', '');
        formik.setFieldValue('assetNumber', '');
        break;
      case 'assetNumber':
        formik.setFieldValue('description', '');
        formik.setFieldValue('status', '');
        break;
      default:
        break;
    }
  };

  const clearFilter = () => {
    if (searchType) setSearchType(searchTypeInitialValue);
    resetFilterQuery();
  };

  useEffect(() => {
    resetFilterQuery();
    resetFilterValues(searchType);
  }, [searchType, formik.values.equipmentGroup]);

  useEffect(() => {
    clearFilter();
  }, [formik.values.equipmentGroup]);

  return (
    <S.Form onSubmit={formik.handleSubmit}>
      <S.Container>
        <Grid>
          <Row span="5">
            <SearchSelect
              value={formik.values.equipmentGroup}
              setValue={(_, value) => handleGroupChange(value as string)}
              options={groupEquipment}
              id="equipmentGroup"
              isLoading={isLoadingEquipmentGroups}
              handlePagination={handlePaginationEquipmentGroups}
              hasPagination={hasNextPageEquipmentGroups}
              hasErrorPagination={!!errorEquipmentGroups}
              label={intl.formatMessage({
                id: 'orthopedicEquipment.group',
              })}
              hasErrors={Boolean(formik.errors.equipmentGroup)}
              errorMessage={String(formik.errors.equipmentGroup)}
              cancelSearch={() => {
                clearFilter();
                formik.setFieldValue('equipmentGroup', '');
                formik.setFieldValue('freeDays', '');
              }}
            />
          </Row>
          <Row span="5">
            <Input
              id="freeDays"
              disabled={true}
              onChange={formik.handleChange}
              label={intl.formatMessage({
                id: 'orthopedicEquipment.exemptDays',
              })}
              variant={'lighter'}
              value={formik.values.freeDays === '' ? 0 : formik.values.freeDays}
            />
          </Row>
          <>
            <Row span="5">
              <SearchSelect
                value={searchType}
                setValue={(_label, value) => {
                  setSearchType(value as OrthopedicEquipmentSearchType);
                }}
                options={searchForEquipments}
                id="searchFor"
                label={intl.formatMessage({
                  id: 'orthopedicEquipment.searchFor',
                })}
                cancelSearch={clearFilter}
              />
            </Row>
            {renderSearchOptions()}
            <div>
              <ButtonLink
                type="submit"
                style={{
                  marginTop: '1.5rem',
                  display: searchType ? 'inline-block' : 'none',
                }}
                disabled={
                  !formik.values.description &&
                  !formik.values.status &&
                  !formik.values.assetNumber
                }
              >
                <S.CustomRoundedIcon>
                  <GlassSearch />
                </S.CustomRoundedIcon>
              </ButtonLink>
            </div>
            <Button
              paymentSlipPage
              type="button"
              style={{
                marginTop: '1.5rem',
                display:
                  formik.values.description ||
                  formik.values.status ||
                  formik.values.assetNumber
                    ? 'inline-block'
                    : 'none',
              }}
              onClick={clearFilter}
            >
              {intl.formatMessage({
                id: 'orthopedicEquipment.button.clearFilter',
              })}
            </Button>
          </>
        </Grid>
      </S.Container>
    </S.Form>
  );
};

export default OrthopedicEquipmentFilter;
